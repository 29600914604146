import React, { useEffect, useCallback, useState } from 'react';
import Web3 from 'web3';
import { QubicConnector } from '@qubic-js/react';
import { Web3ReactProvider, useWeb3React } from '@web3-react/core';
import qs from 'query-string';
import ReactGA from "react-ga4";

// import logo from './logo.svg';
import QubicLogo from './assets/img_qubic_logo.png';
import PolygonTop from './assets/img_polygon_top.png';
import PolygonDown from './assets/img_polygon_down.png';
import FacebookFill from './assets/ic_facebook_fill.svg';
import InstagramFill from './assets/ic_instagram_fill.svg';
import CheckFill from './assets/ic_check_circle_green_fill.svg';
import QubicNFT from './assets/videos/qubic-nft.mp4';

import './App.sass';

import InlineDownloadButtons from './components/InlineDownloadButtons';

const qubicConnector = new QubicConnector( {
  apiKey: 'eef6979f-38b3-4d14-a1ce-071549886814',
  apiSecret: 'ZneKPssapGRXzfoKNgwqVzozhIIeVwno',
  chainId: 1,
  infuraProjectId: '',
  // optional, default: false, when value is true, the popup will hide automatically
  autoHideWelcome: false,
  // optional, default: false, when value is true, the show iframe instead of new window, credit card payment will failed with this option value true
  enableIframe: false,
});

const App = () => {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: 'G-6KJPR0CKHT',
      },
    ]);
    ReactGA.send('pageview');
  }, []);

  const context = useWeb3React<Web3>();
  const { account, activate } = context;

  const { client = "Qubic", tag = "" } = qs.parse(window.location.search);

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (done || !account) return;

    fetch(qs.stringifyUrl({
      url: 'https://script.google.com/macros/s/AKfycbzqZLQGvmSpufM8PWHEgfD2QIjlUZU-4NkoB7oCJpuuBhVsRMUE-V_p-MLoQkk8d_GTKA/exec',
      query: {
        client,
        tag,
        address: account,
      },
    }))
    .then(res => res.json())
    .then(result => {
      const { status } = result;
      if (status === 'success') {
        setDone(true);
      }
    });
  }, [done, account, client, tag]);

  const handleSignIn = useCallback(async () => {
    await activate(qubicConnector, (e: Error): void => {
      console.error(e);
    });
  }, [activate]);

  return (
    <div className="App">
      <header>
        <img src={QubicLogo} alt="Logo" />
      </header>
      <main>
        <div className="content">
          <div>
            <section className="asset">
              <div className="left-bg">
                <img src={PolygonTop} alt="Left Bg" />
              </div>
              <div className="card">
                <div className="media-mask">
                  <video muted loop autoPlay playsInline>
                    <source type="video/mp4" src={QubicNFT}></source>
                  </video>
                  {done && (
                    <div className="redeemed">
                      <img src={CheckFill} alt="Green Check" />
                      <div>已領取</div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div>
            <section className="info">
              <div className="right-bg">
                <img src={PolygonDown} alt="Right Bg" />
              </div>
              <h3>{!done ? 'Hi, We Are Qubic!' : 'NFT 登記成功'}</h3>
              <div className="description">
                {!done ? (
                  <>
                    我們相信 NFT 獨一無二、具有流通性、與不可篡改的特性，可以為更多產業、領域開拓出全新變化及商業可能！
                    <br /><br />
                    期望透過 Qubic，所有人無需理解複雜區塊鏈技術，都能無痛接軌 Web 3.0
                  </>
                ) : (
                  <>
                    NFT 將儘速發送至您的錢包
                    <br /><br />
                    您可以先下載 Qubic iOS / Android App 或使用 Web 版查看您的 NFT
                  </>
                )}
              </div>
              {done && (
                <div className="download">
                  <InlineDownloadButtons spacing={8} maxHeight={54} />
                </div>
                
              )}
              <div className="button-group">
                {!done && (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <a className="button" onClick={handleSignIn}>領取 NFT 空投</a>
                )}
                <a className="button outline" href="https://creator.qubic.market" target="_blank" rel="noreferrer">查看 Qubic Creator →</a>
              </div>
            </section>
          </div>
        </div>
      </main>
      <footer>
        <div className="social">
          <a href="https://www.facebook.com/QubicWallet/" target="_blank" rel="noreferrer"><img src={FacebookFill} alt="Facebook" /></a>
          <a href="https://www.instagram.com/qubic_nft/" target="_blank" rel="noreferrer"><img src={InstagramFill} alt="Instagram" /></a>
        </div>
        <div>© AMIS Technologies Co., Ltd.</div>
      </footer>
    </div>
  );
}

export default React.memo(() => {
  const library = (provider: any): Web3 => {
    console.log({
      'provider.isQubic': provider.isQubic,
    });
    return new Web3(provider);
  };

  return (
    <Web3ReactProvider getLibrary={library}>
      <App />
    </Web3ReactProvider>
  );
});
