import React, { useEffect, useState, useRef, useMemo } from 'react';
import MobileDetect from 'mobile-detect';

import './InlineDownloadButtons.sass'

import AppStoreDownload from '../assets/img_download_appstore.svg';
import GooglePlayDownload from '../assets/img_download_googleplay.svg';
import WebappDownload from '../assets/img_download_webapp.svg';

type IconKey = 'applestore' | 'googleplay' | 'webapp';

const icons: { [K in IconKey]: { ratio: number, source: string, url: string} } = {
  applestore: {
    ratio: 146.95 / 54,
    source: AppStoreDownload,
    url: 'https://apps.apple.com/tw/app/id1563987988',
  },
  googleplay: {
    ratio: 181.34 / 54,
    source: GooglePlayDownload,
    url: 'https://play.google.com/store/apps/details?id=app.qubic.wallet',
  },
  webapp: {
    ratio: 146.95 / 54,
    source: WebappDownload,
    url: 'https://wallet.qubic.app',
  },
}

export function useContainerDimensions(myRef: React.RefObject<any>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: (myRef && myRef.current.offsetWidth) || 0,
      height: (myRef && myRef.current.offsetHeight) || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };
    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
}

const InlineDownloadButtons = ({
  maxHeight,
  spacing,
}: {
  maxHeight: number,
  spacing: number,
}) => {
  const componentRef = useRef(null)
  const { width } = useContainerDimensions(componentRef);
  
  const shownButtons: IconKey[] = useMemo(() => {
    let buttons: IconKey[] = [];
    const md = new MobileDetect(window.navigator.userAgent);
    const os = md.os();
    if (os === 'iOS' || os === 'iPadOS') {
      buttons.push('applestore');
    } else if (os === 'AndroidOS') {
      buttons.push('googleplay');
    } else {
      buttons.push('applestore');
      buttons.push('googleplay');
    }
    buttons.push('webapp');
    return buttons;
  }, []);
  const totalRatio = shownButtons.reduce((result, current) => result += icons[current].ratio, 0);
  const finalHeight = (width - (spacing * (shownButtons.length - 1))) / totalRatio;

  return (
    <div ref={componentRef} className="download-button-group" style={{ height: maxHeight > finalHeight ? finalHeight : maxHeight }}>
      {width > 0 && shownButtons.map((button, index) => (
        <a
          key={button}
          href={icons[button].url}
          style={{
            marginRight: index !== shownButtons.length - 1 ? spacing : 0
          }}
          target="_blank"
          rel="noreferrer"
        >
          <img src={icons[button].source} alt="Download Icon" />
        </a>
      ))}
    </div>
  )
}

export default InlineDownloadButtons;
